<template>
  <v-card flat color="white" light style="height: 100%" class="d-flex flex-column justify-space-between">
    <div class="white rounded-lg pa-3 elevation-0">
      <v-img
        contain
        :height="$vuetify.breakpoint.mdAndDown ? '200px' : '250px'"
        content-class="d-flex justify-space-between"
        :src="
          product.pictures
            ? 'https://onlineapi.anlas.com/' + product.pictures
            : require('@/assets/images/misc/not-image.jpg')
        "
      >
        <v-chip color="primary" label x-small class="px-2 chip-rotate">
          {{ product.product_code }}
        </v-chip>
        <v-btn icon outlined color="primary" @click="isCardDetailsVisible = !isCardDetailsVisible">
          <v-icon>
            {{ icons.mdiInformation }}
          </v-icon>
        </v-btn>
      </v-img>
      <h3 class="text-body-1 font-weight-medium pt-3">
        {{ product.product_title }}
      </h3>
    </div>
    <div class="pa-3">
      <v-expand-transition>
        <div v-show="isCardDetailsVisible" class="mt-3">
          <v-divider></v-divider>
          <div class="py-2 text-body-2" v-html="product.kisa_aciklama" />
          <v-divider></v-divider>
        </div>
      </v-expand-transition>

      <v-row v-if="userData.role === 'PLSYR'" dense>
        <v-col cols="12">
          <v-select
            v-model="kondisyonDegeri"
            outlined
            dense
            background-color="light"
            hide-details
            :items="kondisyonItems"
            @change="condChange(product)"
          ></v-select>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="6" xl="12">
          <v-card class="mb-2" outlined rounded="lg">
            <Counter
              ref="basketCNT"
              :birim="product.birim"
              :birim-ritim="50"
              bg-color="transparent"
              button-color="night"
              :paym-cond="kondisyonDegeri"
              :dark="false"
              :light="true"
              :status="false"
              @selected="selected"
            ></Counter>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" xl="12">
          <v-btn
            depressed
            block
            x-large
            style="height: 55.5px !important"
            color="info"
            :loading="loading"
            class="text-capitalize white--text px-0"
            @click="addToBasket(product.plants, product.product_title, product.id, count, product.birim)"
          >
            Sepete At
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import useVuetify from '@core/utils/vuetify'

import Counter from '@/components/CounterTo.vue'
import store from '@/store'
import {
  mdiAccountOutline,
  mdiCartPlus,
  mdiChevronDown,
  mdiHelpCircleOutline,
  mdiInformation,
  mdiLockOpenOutline,
  mdiShareVariantOutline,
  mdiStarOutline,
  mdiTrendingUp,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  components: {
    Counter,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 1,
    },
    kondisyonDeger: {
      type: String,
      default: '',
    },
    kondisyonItems: {
      type: Array,
      default: [],
    },
  },
  emits: ['info'],
  setup(props, { emit }) {
    const { rootThemeClasses } = useVuetify()
    const userData = JSON.parse(localStorage.getItem('userData'))
    const isCardDetailsVisible = false
    const { isDark, menuIsVerticalNavMini } = useAppConfig()
    const rating = ref(5)
    const count = ref(1)
    const counts = computed(() => Array.from({ length: 100 }, (_, i) => i + 1))
    const kondisyonDegeri = ref(props.kondisyonDeger)
    const producti = ref(props.product)
    const info = (product, qty, birim) => {
      emit('addInfo', { product, qty, birim })
    }
    const selected = number => {
      count.value = number.value
    }
    const condChange = val => {
      const paymcond = kondisyonDegeri.value == '100' ? kondisyonDegeri.value : `0${kondisyonDegeri.value}`

      val.priceAll.forEach(el => {
        if (el.paymcond == paymcond) {
          if (el.plant == '01') {
            val.product_price_01 = el.price1
          } else if (el.plant == '02') {
            val.product_price_02 = el.price1
          }
        }
      })
    }

    return {
      rootThemeClasses,
      isCardDetailsVisible,
      rating,
      selected,
      count,
      counts,
      isDark,
      menuIsVerticalNavMini,
      info,
      userData,
      condChange,
      kondisyonDegeri,

      // icons
      icons: {
        mdiInformation,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    addBasket(plant, product, id, count, birim) {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid: id,
          qty: count,
          paymCond: this.kondisyonDegeri,
          plant,
        })
        .then(response => {
          this.loading = false
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              html: response.detail.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',

              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.$refs.basketCNT.changeNumber()
            this.info(product, count, birim)
          }
        })
    },
    async addToBasket(plants, product, id, count, birim) {
      this.loading = true

      //değişen kısım if (plants.length === 1)
      if (plants.length === 1 || plants.length === 2) {
        this.addBasket(plants[0].plant, product, id, count, birim)

        this.loading = false

        return
      }

      const plantsMap = new Map(plants.map(object => [object.plant, object.stext]))

      const { value: plant } = await Vue.swal({
        icon: 'info',
        title: 'Fabrika Seçimi',
        text: 'Lütfen ürünü eklemek istediğiniz fabrikayı seçiniz',
        input: 'select',
        inputOptions: plantsMap,
      })

      if (plant) {
        this.addBasket(plant, product, id, count, birim)
      }
      this.loading = false
    },
    productPrice(items) {},
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.chip-rotate {
  transform: rotate(90deg) translateY(0px) translateX(-16px);
  transform-origin: left bottom;
}
.chip-content {
  .v-chip__content {
    width: 100%;
    justify-content: space-between !important;
  }
}
// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}
</style>
