<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row :dense="$vuetify.breakpoint.mdAndDown">
      <v-col
        v-for="(product, index) in products"
        :key="product.id"
        cols="6"
        md="4"
        :lg="menuIsVerticalNavMini ? 3 : 4"
        :xl="menuIsVerticalNavMini ? 2 : 3"
      >
        <order-card-modern
          :product="product"
          :kondisyon-deger="kondisyonDeger"
          :kondisyon-items="kondisyonItems"
          :index="index"
          @addInfo="addInfo"
        ></order-card-modern>
      </v-col>
    </v-row>
    <v-snackbars
      :messages.sync="snackbar"
      width="500"
      color="success"
      :timeout="1500"
      bottom
      right
      transition="slide-y-reverse-transition"
    >
      <template v-slot:default="{ message }">
        <div class="text-body-1 font-weight-bold">
          {{ message.product }}
        </div>
        <div>
          Ürününden <span class="text-body-1 font-weight-bold">{{ message.qty }} {{ message.birim }}</span> sepetinize
          eklendi
        </div>
      </template>
      <template v-slot:action="{ close }">
        <v-btn
          icon
          small
          @click="close()"
        >
          <v-icon small>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbars>
  </v-container>
</template>
<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { mdiBasket, mdiClose } from '@mdi/js'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import store from '@/store'
import OrderCardModern from './OrderCardModern.vue'

export default {
  components: {
    OrderCardModern,
    VSnackbars,
  },
  props: {
    kondisyonDeger: {
      type: String,
      default: '',
    },
    kondisyonItems: {
      type: Array,
      default: [],
    },
  },
  setup(props, { emit }) {
    const userData = JSON.parse(localStorage.getItem('userData'))

    // ————————————————————————————————————
    //* ——— Router
    // ————————————————————————————————————
    const { menuIsVerticalNavMini } = useAppConfig()

    const { route } = useRouter()
    const snackbar = ref([])

    // Search Query & Route Params

    const routeParams = computed(() => route.value.params)
    const products = ref([])
    const page = ref(1)
    const categoryId = ref(0)

    // eslint-disable-next-line no-shadow
    const addInfo = addInfo => {
      snackbar.value.push(addInfo)
    }
    const fetchProducts = () => {
      store
        .dispatch('app-order/fetchProducts', {
          method: 'getProductsByCtgId',
          id: categoryId.value,
          page: page.value,
          count: 6,
          plant: '01',
          delivery_date: store.state['app-order'].deliveryDate,
        })
        .then(response => {
          const paymcond = props.kondisyonDeger == '100' ? props.kondisyonDeger : `0${props.kondisyonDeger}`
          const productList = response.detail.products.map(item => {
            const items = {}
            items.id = item.id
            items.product_code = item.urun_kodu
            items.product_title = item.baslik
            items.paymCond = props.kondisyonDeger
            items.pictures = item.resim
            items.product_01 = true
            items.product_02 = true
            items.kisa_aciklama = item.kisa_aciklama
            items.birim = item.birim
            items.plants = item.plants
            items.priceAll = item.priceAll
            if (item.priceAll.length > 0) {
              if (userData.role === 'PLSYR' && props.kondisyonDeger != '003') {
                item.priceAll.forEach(el => {
                  if (el.paymcond == paymcond) {
                    if (el.plant == '01') {
                      items.product_price_01 = el.price1
                    } else if (el.plant == '02') {
                      items.product_price_02 = el.price1
                    }
                  }
                })
              } else {
                const price_01 = item.priceAll.find(i => i.plant === '01')
                const price_02 = item.priceAll.find(i => i.plant === '02')
                if (price_01) {
                  items.product_price_01 = price_01?.price1
                }
                if (price_02) {
                  items.product_price_02 = price_02?.price1
                }
              }
            }
            if (typeof items.product_price_01 === 'undefined') {
              items.product_01 = false
            }
            if (typeof items.product_price_02 === 'undefined') {
              items.product_02 = false
            }

            return items
          })
          emit('changeStatus')
          products.value = productList
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    onMounted(() => {
      fetchProducts()
    })

    // Fetch email again of search query change or route param change
    // eslint-disable-next-line no-use-before-define
    watch([routeParams], () => {
      categoryId.value = route.value.params.categoryId
      fetchProducts()
    })

    watch(
      () => store.getters['app-order/doneDelivery'],
      () => {
        fetchProducts()
      },
    )

    return {
      addInfo,
      snackbar,
      routeParams,
      fetchProducts,
      products,
      page,
      categoryId,
      menuIsVerticalNavMini,
      icons: {
        mdiBasket,
        mdiClose,
      },
    }
  },
}
</script>
